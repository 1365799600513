import React, { useEffect, useState } from "react";

import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/reverse-asin-lookup/HomeIntro";
import Steps from "../components/reverse-asin-lookup/Steps";
import Section from "../components/reverse-asin-lookup/Section";
import OgImg from "../assets/images/raster/components/common/mainscreen_reverse_asin_lookup.png";
import { isSavedUserInfo } from "../helpers";
import ReviewSection from "../components/common/ReviewSection";
import FormSectionMiddle from "../components/reverse-asin-lookup/FormSectionMiddle";
import FormSectionBottom from "../components/reverse-asin-lookup/FormSectionBottom";

const PgRAL = () => {
  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsSecondStateCollectEmail(isSavedUserInfo());
    setIsLoading(false);
  }, []);

  return (
    <Layout isSecondStateCollectEmail={isSecondStateCollectEmail}>
      <Seo
        title="Amazon Reverse ASIN Lookup Tool: Discover Competitors’ Keywords"
        description={
          "Reverse ASIN Lookup is the best tool for Amazon keyword research. Find out the list of competitors' high-rank keywords by entering their ASIN number. Try for free."
        }
        page="reverse-asin-lookup"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        isLoading={isLoading}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
      />
      <ReviewSection />
      <Steps />
      <FormSectionMiddle
        isLoading={isLoading}
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
      />
      <Section />
      <FormSectionBottom
        isLoading={isLoading}
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
      />
    </Layout>
  );
};

export default PgRAL;
