import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import IntroEmailCollectContainer from "../../../containers/common/forms/IntroEmailCollectContainer";
import "./index.scss";

const EmailCollector = ({
  isLoading,
  isSecondStateCollectEmail,
  setIsSecondStateCollectEmail,
  isIntro,
}) => {
  return (
    <IntroEmailCollectContainer
      page={PAGES.REVERSE_ASIN}
      pageFormState={isLoading ? false : isSecondStateCollectEmail}
      updatePageFormState={isLoading ? null : setIsSecondStateCollectEmail}
      title="TRY Reverse ASIN Lookup For Free!"
      customClass={`PgRAL-EmailCollector ${
        isIntro ? "PgRAL-EmailCollector_intro" : ""
      }`}
      buttonTextFirstState="Try for Free!"
      buttonTextSecondState="GET REVERSE ASIN LOOKUP FOR FREE "
    />
  );
};

EmailCollector.propTypes = {
  isIntro: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isSecondStateCollectEmail: PropTypes.bool.isRequired,
  setIsSecondStateCollectEmail: PropTypes.func.isRequired,
};

export default EmailCollector;
