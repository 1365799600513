import React from "react";
import PropTypes from "prop-types";
import EmailCollector from "../EmailCollector";
import "./index.scss";

const FormSectionMiddle = ({
  isLoading,
  isSecondStateCollectEmail,
  setIsSecondStateCollectEmail,
}) => {
  return (
    <section className="PgRAL-FormSectionMiddle">
      <EmailCollector
        isLoading={isLoading}
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
      />
    </section>
  );
};

FormSectionMiddle.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSecondStateCollectEmail: PropTypes.bool.isRequired,
  setIsSecondStateCollectEmail: PropTypes.func.isRequired,
};

export default FormSectionMiddle;
