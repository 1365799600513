import React from "react";
import PropTypes from "prop-types";
import EmailCollector from "../EmailCollector";
import "./index.scss";

const FormSectionBottom = ({
  isLoading,
  isSecondStateCollectEmail,
  setIsSecondStateCollectEmail,
}) => {
  return (
    <section className="PgRAL-FormSectionBottom">
      <div className="container">
        <div className="PgRAL-FormSectionBottom-info">
          <div className="PgRAL-FormSectionBottom__title">
            See what keywords your product ranks for!
          </div>
          <div className="PgRAL-FormSectionBottom__subtitle">
            Start a free trial of Reverse ASIN Lookup now and{" "}
            <span>check up to 10 products</span>
          </div>
        </div>
        <EmailCollector
          isLoading={isLoading}
          isSecondStateCollectEmail={isSecondStateCollectEmail}
          setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
        />
      </div>
    </section>
  );
};

FormSectionBottom.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSecondStateCollectEmail: PropTypes.bool.isRequired,
  setIsSecondStateCollectEmail: PropTypes.func.isRequired,
};

export default FormSectionBottom;
